import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import {connect} from "../../state/useGlobal";
import {Redirect, withRouter} from "react-router";
import {Helmet} from "react-helmet";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import Loading from "../../components/Loadingv2";
import ProductModal from "../../components/ProductModal";
import FakeCartModal from "../../components/FakeCartModal";
import CartSteps from "../../components/CartSteps";
import history from "../../routes/history";
import mixpanel from 'mixpanel-browser';
import {PRODUCT_TYPE_CONFIGURABLE} from "../../constants/common";
import FakeCart from "../../components/FakeCart";
import Button from "../../components/Button";
import {ArrowRightIcon} from "@heroicons/react/outline";
import ConfProductPackageConfigurable from "../../components/DetailListView/ConfProduct/ConfProductPackageConfigurable";
import SimpleProductConfigurable from "../../components/DetailListView/SimpleProduct/SimpleProductConfigurable";
import GravurModal from "../../components/GravurModal";
import {elementInViewport} from "../../services/helper";
import ConfProduct from "../../components/DetailListView/ConfProduct";
import SimpleProduct from "../../components/DetailListView/SimpleProduct";

class ConfigProductInPackageScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            schoolCode: this.props.match.params.id,
            lastStep: false,
        };
    }

    componentDidMount() {
        const {ga4, globalState, globalActions} = this.props;
        try {
            ga4.pageview('/calcuso-group-order-configurable-package', '/calcuso-group-order-configurable-package', 'Group Order | Configurable package');
            mixpanel.track_pageview({
                "page": "Group Order | Configurable package",
                "school_code": this.state.schoolCode,
            });
        } catch (e) {
            console.log(e);
        }
        // Check if cart have related package selected
        // check calculator has package
        const {groupOrder} = globalState;
        const {calculator} = groupOrder;

        const hasPackage = calculator.related_package.filter((item) => item.version === 'package').length > 0;

        if (globalActions.getSortCartItems().length === 0 || (!globalState.fakeCart.relatedPackageSelected && !hasPackage)) {
            this.props.history.push(`/group-orders/${this.state.schoolCode}/products`);
        }

        // Reset all step
        globalActions.resetConfigurableStep();
        setTimeout(() => {
            this.setState({isLoading: false});
        }, 1500);
        globalActions.closeGravurModal()
        setTimeout(() => {
            if (elementInViewport(document.getElementById('last-position'))) {
                this.setState({lastStep: true});
            }
        }, 1600);
    }

    render() {
        const {t, globalState, globalActions} = this.props;
        const {isLoading, schoolCode} = this.state;
        const {groupOrder} = globalState;
        const {status, calculator} = groupOrder;
        const handleClickBack = () => {
            history.push(`/group-orders/${globalState.groupOrder.school_code.trim()}/products`)
        }

        // add scroll event user scroll to last item
        window.onscroll = () => {
            try {
                const lastestItem = globalActions.getSortCartItemsInPackageOnly().slice(-1)[0];
                if (elementInViewport(document.getElementById(`config-${lastestItem.id}`))) {
                    this.setState({lastStep: true});
                }
            } catch (e) {
                // console.log(e);
            }
            try {
                if (elementInViewport(document.getElementById('last-position'))) {
                    this.setState({lastStep: true});
                }
            } catch (e) {

            }
        }

        const handleClickNext = () => {
            if (globalState.configurableStep.canSeeLastItem || this.state.lastStep) {
                history.push(`/group-orders/${globalState.groupOrder.school_code.trim()}/preview`)
            } else {
                // Move to next step
                globalActions.goToNextStepConfigurable(globalActions.getSortCartItemsInPackageOnly(), null);
            }
        };

        return (
            <AppLayout contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6" textColor="text-gunmetal-black" border={false}>
                {isLoading ? (
                    <Loading/>
                ) : (
                    <>
                        {status === STATUS_NOT_FOUND ? (
                            <Redirect to="/"/>
                        ) : (
                            <>
                                <Helmet>
                                    <title>
                                        {schoolCode} | {groupOrder?.school?.name} | {t("Group Order")}
                                    </title>
                                </Helmet>
                                <Header title={t("Group Order")} subTitle={groupOrder?.school?.name}>
                                    <CartSteps/>
                                </Header>
                                <div className="relative mt-10 mx-auto max-w-6xl px-6 lg:px-8 pb-20 bg-cal-primary-floral-white-E0DACC lg:bg-cal-primary-floral-white-FEF9EF">
                                    <h1 className="text-center font-bold text-3xl py-10 text-cal-primary-celadon-green-008E8D">
                                        {t("Configure your school package")}
                                    </h1>
                                    <div className="flex flex-col gap-7 pb-10">
                                        {globalActions.getSortCartItems().filter((item) => {
                                            return item.product.product_id === calculator.product_id || globalState?.fakeCart?.fulfillProductDiscountIds?.includes(parseInt(item.product.product_id));
                                        }).map((item) =>
                                            item.product.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                <div key={item.product.product_id}>
                                                    <ConfProductPackageConfigurable
                                                        key={item.product.product_id}
                                                        item={item.product}
                                                    />
                                                    {globalState.fakeCart.relatedPackageSelected === null ? (
                                                        <div id="config-relateds" className="mt-4 space-y-4">
                                                            {calculator?.product_related &&
                                                                calculator.product_related.map((productRelated) =>
                                                                    productRelated.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                                        <div id={'config-' + productRelated.product_id}>
                                                                            <ConfProduct
                                                                                key={productRelated.product_id}
                                                                                item={productRelated}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div id={'config-' + productRelated.product_id}>
                                                                            <SimpleProduct
                                                                                key={productRelated.product_id}
                                                                                item={productRelated}
                                                                            />
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    ) : ''}
                                                </div>
                                            ) : (
                                                <SimpleProductConfigurable
                                                    key={item.product.product_id}
                                                    item={item.product}
                                                />
                                            )
                                        )}
                                    </div>
                                    <span className="w-0" id="last-position"></span>
                                </div>
                                <div className="fixed inset-x-0 bottom-0">
                                    <div className="h-24 border-t-2 bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6 border-floral-white-800">
                                        <div className="max-w-2xl px-4 py-4 mx-auto lg:max-w-7xl md:px-5 md:py-6 lg:px-8 lg:py-8">
                                            <div className="flex items-center justify-end md:justify-between space-x-4">
                                                <FakeCart schoolCode={globalState.groupOrder.school_code} showMobile={false}/>
                                                <div className="flex items-center justify-end space-x-4">
                                                    <p
                                                        onClick={handleClickBack}
                                                        className="font-medium cursor-pointer text-gunmetal-black-500"
                                                    >
                                                        {t("back")}
                                                    </p>
                                                    <Button
                                                        onClick={handleClickNext}
                                                        id="add-product-button-scroll"
                                                        type="button"
                                                        color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                                        size="xl"
                                                    >
                                                        <span>{t("next")}</span>
                                                        <ArrowRightIcon
                                                            className="w-5 h-5 ml-3 -mr-1 "
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                <ProductModal/>
                <FakeCartModal/>
                <GravurModal/>
            </AppLayout>
        );
    }
}

export default withRouter(connect(withTranslation()(ConfigProductInPackageScreen)));
