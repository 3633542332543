import React from "react";
import useGlobal from "../../../state/useGlobal";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import {
  PRODUCT_TYPE_CONFIGURABLE,
  IS_NOT_GRAVIUR,
  ATTRIBUTE_CODE_COLOR,
  IS_GRAVIUR,
  REGEX_GRAVIUR,
  REGEX_GRAVIUR_REPLACE,
} from "../../../constants/common";
import Gravur from "./Options/Gravur";
import { Formik } from "formik";
import * as Yup from "yup";
import BADGE_CONSTANTS from "../../../constants/badge";
import Badge from "../../Badge";
import BigNumber from "bignumber.js";
import RecursiveOptionsContainer from "./RecursiveOptionsContainer";
import PriceContainer from "./PriceContainer";
import * as Sentry from "@sentry/react";
import ImageContainer from "./ImageContainer";
import EmptyIcon from "../../Icons/EmptyIcon";

export default function ConfProduct({ item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  // Get Info From Cart
  const { fakeCart } = globalState;
  const { items } = fakeCart;
  const selectedItem = items[item.product_id];

  // Get The lowest price
  const { product_simples, product_tags, configurable_product_options } = item;
  const lowestPrice = product_simples.reduce(function (a, b) {
    return a.product_price <= b.product_price ? a : b;
  }, {});
  const lowestColor = lowestPrice.product_custom_attributes.find(
    (product_custom_attribute) =>
      product_custom_attribute.attribute_code === ATTRIBUTE_CODE_COLOR
  );

  const getFinalPrice = (values, force = true) => {
    // If Product In Cart
    if (selectedItem && !force) {
      return selectedItem.price;
    }

    const product = product_simples.find((product_simple) => {
      let find = true;
      for (
        let index = 0;
        index < product_simple.product_custom_attributes.length;
        index++
      ) {
        const element = product_simple.product_custom_attributes[index];
        if (element.value !== values[element.attribute_code]) {
          return false;
        }
      }

      return find;
    });

    if (!product) {
      Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
      Sentry.setUser({ item, globalState });
      Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
      return;
    }

    if (values.is_graviur === IS_GRAVIUR) {
      // Gravur
      const gravurOption = item.product_custom_options.find(
        (custom_option) => custom_option.type === "field"
      );
      const price = new BigNumber(product.product_price).plus(
        new BigNumber(gravurOption.price)
      );
      return price.toFormat(2).toString();
    }

    const price = new BigNumber(product.product_price);
    return price.toFormat(2).toString();
  };

  const initialValues = configurable_product_options.reduce((obj, item) => {
    let lowestAttribute = lowestPrice.product_custom_attributes.find(
      (product_custom_attribute) =>
        product_custom_attribute.attribute_code === item.attribute_code
    );
    return Object.assign(obj, {
      [item.attribute_code]: selectedItem?.options[item.attribute_code]
        ? selectedItem?.options[item.attribute_code]
        : lowestAttribute?.value,
    });
  }, {});

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      const { target } = keyEvent;
      target.blur();
    }
  }

  return (
    <div className="w-full p-4 bg-white rounded-lg ">
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          color: selectedItem?.options?.color
            ? selectedItem?.options?.color
            : lowestColor?.value,
          gravur: selectedItem?.options?.gravur
            ? selectedItem?.options?.gravur
            : "",
          is_graviur: selectedItem?.options?.gravur
            ? IS_GRAVIUR
            : IS_NOT_GRAVIUR,
        }}
        onSubmit={async (values) => {
          const price = getFinalPrice(values);
          const payload = {
            id: item.product_id,
            name: item.name,
            sku: item.product_sku,
            type: item.product_type,
            qty: 1,
            price: new BigNumber(price).toFormat(2).toString(),
            product: item,
            options: {
              ...values,
            },
            created_at: new Date().getTime(),
          };
          globalActions.addToCart(payload);
        }}
        validationSchema={Yup.lazy((values) => {
          const val = values.gravur
            ? values.gravur.replace(new RegExp(REGEX_GRAVIUR_REPLACE), "")
            : "";
          return Yup.object().shape({
            gravur: Yup.string()
              .max(22)
              .matches(
                REGEX_GRAVIUR,
                t("Please remove invalid characters {{invalid}} .", {
                  invalid: val,
                })
              ),
          });
        })}
        validateOnChange
      >
        {(formik) => {
          const { handleSubmit } = formik;

          return (
            <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-5">
                <div className="flex space-x-2">
                  <div onClick={() => globalActions.openProductModal(item)}>
                    <InformationCircleIcon className="w-8 h-8 rounded-full cursor-pointer bg-floral-white-dark-500 text-cal-primary-gunmetal-black-202833" />
                  </div>
                  <div
                    className="flex items-center flex-1 cursor-pointer"
                    onClick={() => globalActions.openProductModal(item)}
                  >
                    <ImageContainer formik={formik} item={item} />
                  </div>
                </div>
                <div className="flex-1 col-span-4 space-y-4">
                  <div className="flex flex-col items-center space-y-2 lg:items-start lg:flex-col-reverse lg:space-y-reverse">
                    <h3 className="text-lg font-medium md:text-xl text-gunmetal-black-500">
                      {item.name}
                    </h3>
                    <div className="flex-1 space-x-2 space-y-2">
                      {product_tags.map((product_tag) => (
                        <Badge
                          key={product_tag.tag_value}
                          color={BADGE_CONSTANTS.color[product_tag.tag_value]}
                          colorText={
                            BADGE_CONSTANTS.colorText[product_tag.tag_value]
                          }
                        >
                          {BADGE_CONSTANTS.icon[product_tag.tag_value] ? BADGE_CONSTANTS.icon[product_tag.tag_value] : <EmptyIcon className="h-6"/>}
                          <span>{product_tag.tag_name}</span>
                        </Badge>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col space-y-4 lg:space-x-4 lg:space-y-0 lg:flex-row">
                    <div className="flex-col justify-between space-y-2 lg:w-4/6 xl:space-y-4">
                      <div className="space-y-4 text-center ">
                        <div
                          className="text-sm prose text-left"
                          dangerouslySetInnerHTML={{
                            __html: item.short_description,
                          }}
                        />

                        {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                          <>
                            {item?.configurable_product_options ? (
                              <RecursiveOptionsContainer
                                options={item?.configurable_product_options}
                                formik={formik}
                                item={item}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-end space-y-2 lg:w-1/2 xl:space-y-4">
                      {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                        <>
                          {item?.product_custom_options &&
                            item?.product_custom_options.map((custom_option) =>
                              custom_option.type === "field" ? (
                                <div
                                  className="w-full lg:flex-1"
                                  key={custom_option.option_id}
                                >
                                  <Gravur
                                    option={custom_option}
                                    formik={formik}
                                    item={item}
                                  />
                                </div>
                              ) : (
                                ""
                              )
                            )}
                        </>
                      ) : (
                        ""
                      )}
                      <PriceContainer formik={formik} item={item} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
