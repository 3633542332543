'use client'

import {Trans, useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useGlobal from "../../../../state/useGlobal";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import ImageContainer from "../../../../components/DetailListView/ConfProduct/ImageContainer";
import basic from "../../../../images/packages/basic.png";
import best from "../../../../images/packages/best.png";
import smart from "../../../../images/packages/smart.png";
import config from "../../../../config";

export default function ConfigRelatedPackages({item, relatedPackages}) {
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    const {relatedPackageSelected} = fakeCart;
    const [select, setSelect] = useState(relatedPackageSelected ?? null);
    const [userSelected, setUserSelected] = useState(false);
    const {t} = useTranslation();

    function reorderArray(array, reference) {
        const order = reference.reduce((acc, val, index) => {
            acc[val] = index;
            return acc;
        }, {});

        // Also apply sort name if the order is the same

        return array.slice().sort((a, b) => {
            const posA = order[a] !== undefined ? order[a] : reference.length;
            const posB = order[b] !== undefined ? order[b] : reference.length;
            return posA - posB;
        });
    }

    //Find the related package that has the largest related_ids
    const base = relatedPackages.filter((relatedPackage) => relatedPackage.related_ids.map(Number).length === Math.max(...relatedPackages.map((relatedPackage) => relatedPackage.related_ids.map(Number).length)));


    const tiersRelated = relatedPackages.map((relatedPackage) => {
            // Estimate the related products discount
            // Step 2: Reorder relatedPackages based on basePackages
            const baseOrder = base.map(pkg => pkg.related_ids.map(Number)).flat();
            const relatedPackagesSorted = reorderArray(
                relatedPackages.map(pkg => pkg.related_ids.map(Number)).flat(),
                baseOrder
            );

            // Step 3: Filter and sort related products
            let relatedProducts = item.product_related.filter((productRelated) => relatedPackage.related_ids.map(Number).includes(parseInt(productRelated.product_id)));
            relatedProducts.sort((a, b) =>
                relatedPackagesSorted.indexOf(parseInt(a.product_id)) - relatedPackagesSorted.indexOf(parseInt(b.product_id))
            );

            let totalWithDiscount = relatedProducts.reduce((acc, product) => acc + (product.product_price - (product.product_price * relatedPackage.percentage_discount / 100)), 0);
            totalWithDiscount = new BigNumber(totalWithDiscount).decimalPlaces(2, BigNumber.ROUND_DOWN).toNumber();
            let stateForSelectPackage = {
                ...relatedPackage,
                relatedProducts
            }

            relatedProducts.unshift(item);

            let imageUrl = relatedPackage?.image_url ?? '';
            if (imageUrl === '') {
                if (relatedPackage.package_type === 'basic') {
                    imageUrl = basic;
                } else if (relatedPackage.package_type === 'best') {
                    imageUrl = best;
                } else if (relatedPackage.package_type === 'smart') {
                    imageUrl = smart;
                }
            }
            stateForSelectPackage.imageUrl = imageUrl;

            return {
                id: relatedPackage.package_type,
                package_type: relatedPackage.package_type,
                name: relatedPackage.package_label,
                percent: relatedPackage.percentage_discount,
                description: relatedPackage.description,
                features: relatedPackage.features,
                productsListing: relatedProducts,
                position: relatedPackage.package_position,
                mostPopular: relatedPackage.is_most_popular ?? false,
                stateForSelectPackage: stateForSelectPackage,
                totalWithDiscount: totalWithDiscount,
                imageUrl: imageUrl,
            }
        }
    );

    const fakeRelatedPackageForCalculate = {
        id: item.product_id,
        package_type: undefined,
        name: t("Calculator only"),
        percent: 0,
        description: null,
        features: [],
        productsListing: [item],
        mostPopular: false,
        stateForSelectPackage: null,
        totalWithDiscount: 0,
        position: 0,
        imageUrl: config.cdn + config.baseUrlMedia + item.product_image,
    }

    tiersRelated.unshift(fakeRelatedPackageForCalculate);
    tiersRelated.sort((a, b) => a.position - b.position);

    useEffect(() => {
        // if setSelect is null, then set the mostPopular related package as selected
        if (select === null && userSelected === false && fakeCart.userHasSelectRelatedPackage === false) {
            let mostPopularPackage = tiersRelated.find((tier) => tier.mostPopular === true);
            if (mostPopularPackage) {
                setSelect(mostPopularPackage);
            }
        }
    }, [select]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleSelect = (type, previous) => {
        setUserSelected(true);
        globalActions.setUserHasSelectRelatedPackage(true);

        if (type.package_type === undefined || type.package_type === null) {
            // THis is main product only
            globalActions.removeAllRelatedProductIncartBaseOnProductSelected(previous);
            globalActions.calculateTotal();
            setSelect(null);
        } else {
            globalActions.removeAllRelatedProductIncartBaseOnProductSelected(previous);
            globalActions.addRelatedBundlePackage(type.stateForSelectPackage)
            setSelect(type.stateForSelectPackage);
        }
    }
    const name = item.name;

    function isSelect(select, tier) {
        return select?.package_type === tier?.package_type
    }

    return (
        <div className="bg-white py-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto text-center">
                    <p className="mt-2 text-4xl font-bold tracking-tight text-celadon-green-dark-500">
                        {t('Save money with our school packages')}
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    <Trans i18nKey="Choose your suitable school package for the <1>{{name}}</1>" values={{name}}>
                        Choose your suitable school package for the <b>{{name}}</b>
                    </Trans>
                </p>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                    {/*<div*/}
                    {/*    key='simple'*/}
                    {/*    onClick={() => handleSelect(null, select)}*/}
                    {/*    className={classNames(*/}
                    {/*        select === null ? 'ring-2 ring-teal-600' : 'ring-1 ring-gray-200',*/}
                    {/*        'rounded-3xl px-5 py-8 relative pb-20',*/}
                    {/*    )}*/}
                    {/*>*/}
                    {/*    <ConfProductPackage key={item.product_id} item={item}/>*/}
                    {/*    <button*/}
                    {/*        type="button"*/}
                    {/*        onClick={() => handleSelect(null, select)}*/}
                    {/*        className={classNames(*/}
                    {/*            select === null ? 'bg-cal-primary-celadon-green-008E8D text-white' : 'bg-gray-100 text-black',*/}
                    {/*            'rounded-3xl px-6 py-2 text-sm font-semibold leading-6 absolute bottom-1 left-1 right-1 mx-auto mt-5',*/}
                    {/*        )}*/}
                    {/*    >*/}
                    {/*        {select === null ? 'Selected' : 'Select'}*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    {tiersRelated.map((tier) => (
                        <div
                            key={tier.id}
                            onClick={() => handleSelect(tier, select)}
                            className={classNames(
                                isSelect(select, tier) ? 'ring-2 ring-teal-600' : 'ring-1 ring-gray-200',
                                'rounded-3xl px-5 py-8 relative pb-20',
                            )}
                        >
                            <div className="mx-auto flex items-center justify-center mb-8"><img src={tier.imageUrl} alt={tier.name} className="h-28"/></div>
                            <div className="flex items-center justify-between gap-x-4">
                                <h3
                                    id={tier.id}
                                    className={classNames(
                                        tier.mostPopular ? 'text-teal-600 text-4xl' : 'text-gray-900 text-lg ',
                                        'font-semibold leading-8',
                                    )}
                                >
                                    {t(tier.name)}
                                </h3>
                                {tier.mostPopular ? (
                                    <p className="rounded-full text-fiery-rose-700 py-1 text-xs font-semibold leading-5">
                                        {t('Most popular')}
                                    </p>
                                ) : null}
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                            {tier.percent > 0 ? (
                                <p className="mt-6 flex items-baseline gap-x-1 h-10">
                                <span className={classNames(
                                    tier.mostPopular ? 'text-celadon-green-dark-500' : 'text-gray-900',
                                    'text-4xl font-bold tracking-tight',
                                )}>- {tier.percent}%</span>
                                    <span className="text-xs font-light leading-6 text-gray-600">{t("on accessories")}</span>
                                </p>
                            ) : (<p className="mt-6 flex items-baseline gap-x-1 h-10">
                                <span className="text-lg tracking-tight text-gray-900">{t("No discount")}</span></p>)}
                            <ul className="mt-8 flex flex-col space-y-5">
                                {tier.productsListing.map((product) => (
                                    <li onClick={() => globalActions.openProductModal(product)} key={product.product_id} className="text-xs grid grid-cols-12 gap-7">
                                        <div className="w-5 h-5">
                                            <ImageContainer formik={{values: []}} item={product} size="h-5 w-5 rounded mx-0 mr-1"/>
                                        </div>
                                        <div className="col-span-9">{product.name}</div>
                                    </li>
                                ))}
                            </ul>
                            <button
                                type="button"
                                onClick={() => handleSelect(tier, select)}
                                className={classNames(
                                    select?.package_type === tier?.package_type ? 'bg-cal-primary-celadon-green-008E8D text-white' : 'bg-gray-100 text-black',
                                    'rounded-3xl px-6 py-2 text-sm font-semibold leading-6 absolute bottom-1 left-1 right-1 mx-auto mt-5',
                                )}
                            >
                                {select?.package_type === tier?.package_type ? t('Selected') : t('Select')}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
