import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {Trans, withTranslation} from "react-i18next";
import {Redirect, withRouter} from "react-router";
import {connect} from "../../state/useGlobal";
import Loading from "../../components/Loadingv2";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import {Helmet} from "react-helmet";
import Footer from "../../components/Footer";
import CheckoutSteps from "../../components/CheckoutSteps";
import {STEP_CHECKOUT_PAYMENT} from "../../constants/checkoutSteps";
import {SHIPPING_METHOD_TO_PRIVATE, SHIPPING_METHOD_TO_SCHOOL,} from "../../constants/shippingMethod";
import BigNumber from "bignumber.js";
import PaymentMethods from "./PaymentMethods";
import {Link} from "react-router-dom";
import {ChevronUpIcon} from "@heroicons/react/solid";
import classNames from "classnames";
import {Transition} from "@headlessui/react";
import {PT} from "../../constants/storeCode";
import Config from "../../config";
import history from "../../routes/history";
import {calculatePaymentFee, hasPaymentFee} from "./functions/PaymentFeeHelper";
import toast from "react-hot-toast";
import mixpanel from 'mixpanel-browser';
import CartPreviewFromQuote from "./CartPreviewFromQuote";
import {toMoney} from "../../services/money";

class ConfirmPaymentScreen extends Component {
    constructor(props) {
        super(props);
        const id = this.props.match.params.id;

        this.state = {
            isLoading: true,
            schoolCode: id,
            openOverView: true,
            paymentFailed: false,
            oldPaymentFailed: '',
        };

        const {globalState} = this.props;
        const {schoolCode} = this.state;
        const {checkout} = globalState;
        const {billing, email, quote_id} = checkout;

        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(currentUrl);
        const maskedId = urlParams.get('masked-id');
        const toErrorPage =  urlParams.get('to-error-page');
        const oldPaymentFailed =  urlParams.get('payment-method');

        if (toErrorPage === '1') {
            window.location.href = '/#/group-orders/'+ id +'/checkout/error';
            return;
        }

        if (maskedId !== null) {
            if (maskedId !== quote_id) {
                window.location.href = '/#/group-orders/'+ id +'/checkout/error';
                return;
            } else {
                this.state.paymentFailed = true;
                this.state.oldPaymentFailed = oldPaymentFailed;
            }
        }

        if (billing === undefined || billing?.firstname.trim() === '' ||
            billing?.lastname.trim() === '' ||
            billing?.postcode.trim() === '' ||
            billing?.street.trim() === '' ||
            email.trim() === ''
        ) {
            history.push(`/group-orders/${schoolCode.trim()}/checkout/address`);
        }
    }

    componentDidMount() {
        const {t, globalActions, ga4} = this.props;
        globalActions.setCheckout({
            currentCheckoutStep: STEP_CHECKOUT_PAYMENT,
        });
        this._loadGroupOrder();

        try {
            ga4.pageview('/calcuso-group-order-payment', '/calcuso-group-order-payment', 'Group Order | Checkout payment');
            const {schoolCode} = this.state;
            mixpanel.track_pageview({
                "page": "Group Order | Checkout payment",
                "school_code": schoolCode,
            })
        } catch (e) {
            console.log(e);
        }

        if (this.state.paymentFailed) {
            if (this.state.oldPaymentFailed !== null && this.state.oldPaymentFailed !== '') {
                toast.error(t('{{payment}} has rejected the purchase. Please select another payment method.', {payment: this.state.oldPaymentFailed}));
            } else {
                toast.error(t('Your payment failed, Please try again later.'));
            }
        }
    }

    _loadGroupOrder = async () => {
        const {globalActions} = this.props;
        const {schoolCode} = this.state;
        await globalActions.loadGroupOrder(schoolCode);
        await globalActions.loadPaymentMethods();
        this.setState({
            isLoading: false,
        });
    };

    calculatePaymentFee() {
        const {globalState} = this.props;
        const {groupOrder, checkout} = globalState;
        const {configs} = groupOrder;
        const {totals, payment} = checkout;

        return calculatePaymentFee(configs, payment, totals?.base_grand_total)
    }

    render() {

        const {t, globalState} = this.props;
        const {isLoading, schoolCode, openOverView} = this.state;
        const {groupOrder, checkout} = globalState;
        const {status, school_code, store, configs} = groupOrder;
        const {totals, shipping, billing, shippingMethodCode} = checkout;

        let links = Config.links[globalState?.store] || {
            LEGAL_NOTICE: "https://www.calcuso.de/impressum/",
            PRIVACY: "https://www.calcuso.de/datenschutz",
            TERMS_OF_USE: "https://www.calcuso.de/agb",
            CANCEL_POLICY: "https://www.calcuso.de/widerruf",
        };

        return (
            <AppLayout
                contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
                textColor="text-gunmetal-black"
                border={false}
            >
                {isLoading ? (
                    <Loading/>
                ) : (
                    <>
                        {status === STATUS_NOT_FOUND ? (
                            <Redirect to="/"/>
                        ) : (
                            <>
                                <Helmet>
                                    <title>
                                        {t("Payments")} | {schoolCode} | {groupOrder?.school?.name} |{" "}
                                        {t("Group Order")}
                                    </title>
                                    <script
                                        src="https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js"
                                        type="text/javascript"
                                    ></script>
                                </Helmet>
                                <div className="flex flex-col h-full min-h-screen">
                                    <Header title={t("Checkout")}>
                                        <CheckoutSteps/>
                                    </Header>

                                    <div className="flex flex-col justify-between flex-1 h-full">
                                        <div className="flex justify-center flex-1">
                                            <div className="w-full px-2 md:px-4 lg:w-5/6 xl:w-3/4 xl:px-8">
                                                <div className="px-4 pt-6 lg:pt-12">
                                                    <div
                                                        className="flex flex-col-reverse lg:grid lg:grid-cols-3 lg:gap-8 xl:gap-16">
                                                        <div className="flex flex-col col-span-2 space-y-4">
                                                            <PaymentMethods/>
                                                            <div className="border-t-2 border-celadon-green-light-500">
                                                                <p className="py-4 text-xs">
                                                                    <Trans
                                                                        i18nKey="checkoutUserMessagesUnread"
                                                                        components={{
                                                                            // eslint-disable-next-line
                                                                            span1: <a href={links.PRIVACY}
                                                                                      target="_blank"
                                                                                      rel="noreferrer"/>,
                                                                            // eslint-disable-next-line
                                                                            span2: <a href={links.TERMS_OF_USE}
                                                                                      target="_blank"
                                                                                      rel="noreferrer"/>,
                                                                            // eslint-disable-next-line
                                                                            span3: <a href={links.CANCEL_POLICY}
                                                                                      target="_blank"
                                                                                      rel="noreferrer"/>,
                                                                        }}
                                                                    >
                                                                        By placing an order at calcuso.com you're
                                                                        agreeing to our{" "}
                                                                        <span1 className="font-bold underline">
                                                                            Privacy Policy
                                                                        </span1>
                                                                        ,{" "}
                                                                        <span2 className="font-bold underline">
                                                                            Terms and Conditions
                                                                        </span2>
                                                                        {" "}
                                                                        and{" "}
                                                                        <span3 className="font-bold underline">
                                                                            Cancellation policy
                                                                        </span3>
                                                                        .
                                                                    </Trans>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col pb-8 space-y-8">
                                                            <div
                                                                className="flex items-center justify-between"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        openOverView: !openOverView,
                                                                    })
                                                                }
                                                            >
                                                                <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                                                                    {t("Order summary")}:
                                                                </h3>
                                                                <button
                                                                    type="button"
                                                                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"
                                                                >
                                                                    <ChevronUpIcon
                                                                        className={classNames({
                                                                            "w-6 h-6": true,
                                                                            "transform rotate-180": openOverView,
                                                                        })}
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                            <Transition
                                                                show={openOverView}
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0"
                                                                className="space-y-8"
                                                            >
                                                                <ul className="border-t-2 divide-y-2 divide-celadon-green-light-500 border-celadon-green-light-500">
                                                                    {totals &&
                                                                    totals?.items &&
                                                                    totals?.items.length > 0 ? (
                                                                        <CartPreviewFromQuote totals={totals}/>
                                                                    ) : (
                                                                        <li className="py-4 text-center">
                                                                            {t("No items")}
                                                                        </li>
                                                                    )}
                                                                    <li className="py-4 text-sm text-center">
                                                                        <div
                                                                            className="flex items-center justify-between space-x-4 ">
                                                                            <p className="font-medium ">
                                                                                {t("Shipping")}
                                                                            </p>
                                                                            {totals?.base_shipping_incl_tax === 0 ? (
                                                                                <p className="font-medium text-right">
                                                                                    {t("free")}
                                                                                </p>
                                                                            ) : (
                                                                                <p className="font-medium text-right">
                                                                                    {new BigNumber(
                                                                                        totals?.base_shipping_incl_tax
                                                                                            ? totals?.base_shipping_incl_tax
                                                                                            : 0
                                                                                    )
                                                                                        .toFormat(2)
                                                                                        .toString()}{" "}
                                                                                    €
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </li>
                                                                    {
                                                                        totals.discount_amount < 0 &&
                                                                        <li className="py-4 text-sm text-center">
                                                                            <div
                                                                                className="flex items-center justify-between space-x-4 ">
                                                                                <p className="font-medium ">
                                                                                    {t("Discount")}
                                                                                </p>
                                                                                <p className="font-medium text-right">
                                                                                    {toMoney(totals.discount_amount)}
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        hasPaymentFee(configs, checkout.payment) &&
                                                                        <li className="py-4 text-sm text-center">
                                                                            <div
                                                                                className="flex items-center justify-between space-x-4 ">
                                                                                <p className="font-medium ">
                                                                                    {t("Payment method fees")}
                                                                                </p>
                                                                                <p className="font-medium text-right">
                                                                                    {toMoney(this.calculatePaymentFee())}
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    <li className="py-4 text-center">
                                                                        <div
                                                                            className="flex items-center justify-between space-x-4 ">
                                                                            <p className="font-medium ">
                                                                                {t("Total")}
                                                                            </p>
                                                                            <p className="font-medium text-right">
                                                                                {new BigNumber(
                                                                                    totals?.base_grand_total
                                                                                        ? totals?.base_grand_total
                                                                                        : 0
                                                                                ).plus(this.calculatePaymentFee())
                                                                                    .toFormat(2)
                                                                                    .toString()}{" "}
                                                                                €
                                                                            </p>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <div className="space-y-2">
                                                                    <div className="flex items-center justify-between ">
                                                                        <p className="font-medium md:text-lg text-gunmetal-black-500">
                                                                            {t("Delivery address")}
                                                                        </p>
                                                                        <Link
                                                                            className="text-sm font-medium underline text-gunmetal-black-500"
                                                                            to={`/group-orders/${school_code.trim()}/checkout/address`}
                                                                        >
                                                                            {t("edit")}
                                                                        </Link>
                                                                    </div>
                                                                    <div className="flex flex-col ">
                                                                        {shippingMethodCode ===
                                                                        SHIPPING_METHOD_TO_SCHOOL ? (
                                                                            <span
                                                                                className="text-sm text-gunmetal-black-500">
                                        {shipping?.company}
                                      </span>
                                                                        ) : (
                                                                            <span
                                                                                className="text-sm text-gunmetal-black-500">
                                        {shipping?.firstname}{" "}
                                                                                {shipping?.lastname}
                                      </span>
                                                                        )}
                                                                        <span
                                                                            className="text-sm text-gunmetal-black-500">
                                      {shipping?.street}
                                    </span>
                                                                        {shippingMethodCode ===
                                                                        SHIPPING_METHOD_TO_PRIVATE ? (
                                                                            <span
                                                                                className="text-sm text-gunmetal-black-500">
                                        {shipping?.company}
                                      </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <span
                                                                            className="text-sm text-gunmetal-black-500">
                                      {shipping?.postcode} {shipping?.city}
                                    </span>
                                                                        <span
                                                                            className="text-sm text-gunmetal-black-500">
                                      {shipping?.country_id}
                                    </span>
                                                                        {store && store?.code === PT ? (
                                                                            <span
                                                                                className="text-sm text-gunmetal-black-500">
                                        {shipping?.vat_id}
                                      </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="space-y-2 ">
                                                                    <div className="flex items-center justify-between ">
                                                                        <p className="font-medium md:text-lg text-gunmetal-black-500">
                                                                            {t("Billing address")}
                                                                        </p>
                                                                        <Link
                                                                            className="text-sm font-medium underline text-gunmetal-black-500"
                                                                            to={`/group-orders/${school_code.trim()}/checkout/address`}
                                                                        >
                                                                            {t("edit")}
                                                                        </Link>
                                                                    </div>
                                                                    <div className="flex flex-col ">
                                    <span className="text-sm text-gunmetal-black-500">
                                      {billing?.firstname} {billing?.lastname}
                                    </span>
                                                                        <span
                                                                            className="text-sm text-gunmetal-black-500">
                                      {billing?.street}
                                    </span>
                                                                        <span
                                                                            className="text-sm text-gunmetal-black-500">
                                      {billing?.company}
                                    </span>
                                                                        <span
                                                                            className="text-sm text-gunmetal-black-500">
                                      {billing?.postcode} {billing?.city}
                                    </span>
                                                                        <span
                                                                            className="text-sm text-gunmetal-black-500">
                                      {billing?.country_id}
                                    </span>
                                                                        {store && store?.code === PT ? (
                                                                            <span
                                                                                className="text-sm text-gunmetal-black-500">
                                        {billing?.vat_id}
                                      </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Transition>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <Footer/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </AppLayout>
        );
    }
}

export default withRouter(connect(withTranslation()(ConfirmPaymentScreen)));
