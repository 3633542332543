import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import useGlobal from "../../state/useGlobal";
import {XIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import PreviewPackage from "../../screens/PreviewScreen/preview-package";
import PreviewNormal from "../../screens/PreviewScreen/preview-normal";
import CartTotalSegment from "../Cart/CartTotalSegment";

export default function FakeCartModal() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const {fakeCartModal, fakeCart} = globalState;
    const {open} = fakeCartModal;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 z-10 "
                open={open}
                onClose={globalActions.closeFakeCartModal}
            >
                <div className="flex items-end justify-center min-h-screen overflow-hidden text-center md:px-4 md:pt-4 md:pb-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block w-full h-screen overflow-auto text-left align-bottom transition-all transform bg-white md:pb-4 md:px-4 md:rounded-lg md:shadow-xl max-w-prose sm:align-middle">
                            <div className="sticky top-0 z-50 block p-4 bg-white md:pt-6 md:pb-4">
                                <div className="flex items-center justify-between">
                                    <Dialog.Title className="flex-1 text-xl font-medium text-center ">
                                        {t("Cart")}
                                    </Dialog.Title>
                                    <div className="flex justify-end ">
                                        <button
                                            type="button"
                                            className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"
                                            onClick={globalActions.closeFakeCartModal}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XIcon
                                                className="w-8 h-8 text-cal-primary-gunmetal-black-202833"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="min-w-full px-6 py-0 pb-8 m-auto space-y-4 md:py-8 md:p-8 md:pb-12">
                                <div className="mt-8">
                                    <div className="flow-root">
                                        <PreviewPackage/>
                                        <PreviewNormal/>
                                    </div>
                                </div>
                            </div>
                            <CartTotalSegment/>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
